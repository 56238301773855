@import '@aurora/shared-client/styles/_variables.pcss';

.lia-message-container {
  display: flex;
  word-break: break-word;
  margin-bottom: 25px;
  border: 1px solid var(--lia-bs-info);
  padding: 13px 15px;
  border-left: 5px solid var(--lia-bs-info);
  border-radius: var(--lia-bs-border-radius-sm);
  font-size: var(--lia-bs-font-size-sm);
  color: var(--lia-bs-gray-900);
  background-color: var(--lia-bs-white);
  align-items: center;

  @media (--lia-breakpoint-down-md) {
    align-items: flex-start;
  }
}
.lia-reply-container {
  border: 0;
  margin-bottom: 2px;
}

.lia-case-details-button {
  color: var(--lia-bs-info);
}

.lia-meta-data-title {
  font-weight: var(--lia-font-weight-md);
}
